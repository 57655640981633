<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Exit Interview</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-center v-if="questions">
          <v-flex
            xs12
            align-self-center
            fill-height
            pa-4
            v-if="questions.length > 0"
          >
            <v-card class="pa-6">
              <v-form v-model="addcat" ref="addcat">
                <v-layout wrap justify-center>
                  <v-flex
                    xs12
                    pa-2
                    align-self-center
                    text-left
                    v-for="(item, i) in questions"
                    :key="i"
                  >
                    <v-layout wrap>
                      <v-flex xs12 align-self-center pa-1>
                        <span class="kumbhMedium">
                          {{ item.question }}
                        </span>
                      </v-flex>

                      <v-flex
                        xs12
                        align-self-center
                        pa-1
                        v-if="item.answerType == 'text'"
                      >
                        <v-textarea
                          v-model="item.answer"
                          placeholder="Enter your comments here.."
                          :rules="[rules.required]"
                          required
                          outlined
                          dense
                          hide-details
                        ></v-textarea>
                      </v-flex>

                      <v-flex
                        xs12
                        align-self-center
                        pa-1
                        v-if="item.answerType == 'dropdown'"
                      >
                        <v-select
                          v-model="item.answer"
                          placeholder="Choose an option"
                          :rules="[rules.required]"
                          required
                          outlined
                          :multiple="item.isMultiSelect ? true : false"
                          hide-details
                          :items="item.options"
                          item-text="optionvalue"
                          item-value="optionvalue"
                          dense
                        ></v-select>
                      </v-flex>

                      <v-flex
                        xs12
                        align-self-center
                        pa-1
                        v-if="item.answerType == 'checkbox'"
                      >
                        <v-layout wrap>
                          <v-flex
                            xs12
                            align-self-center
                            pa-1
                            v-for="(sub, s) in item.options"
                            :key="s"
                          >
                            <v-checkbox
                              v-model="sub.answer"
                              :label="sub.optionvalue"
                              hide-details
                              color="#005f32"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex xs12 sm4 md3 align-self-center text-center pa-2>
                    <v-btn
                      color="#005f32"
                      dark
                      block
                      class="rounded-xl"
                      @click="validateQn()"
                    >
                      Submit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      questions: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      answerType: null,
      answerTypes: [
        {
          name: "Text",
          val: "text",
        },
        {
          name: "Dropdown",
          val: "dropdown",
        },
        {
          name: "Checkbox",
          val: "checkbox",
        },
      ],
      question: null,
      options: [],
      isRequired: false,
      order: null,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    answerType() {
      this.options = [];
    },
    options() {
      console.log("Op=", this.options);
    },
  },

  methods: {
    addNewOp() {
      var optionval = {
        optionvalue: "",
      };
      this.options.push(optionval);
    },
    editNewOp() {
      var optionvale = {
        optionvalue: "",
      };
      this.editingitem.options.push(optionvale);
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/exitInterviewQuestions/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/exitInterviewQuestions/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.questions = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
    validateQn() {
      // if (!this.question) {
      //   this.msg = "Please provide a question";
      //   this.showsnackbar = true;
      //   return;
      // } else if (!this.answerType) {
      //   this.msg = "Please choose an answer type";
      //   this.showsnackbar = true;
      //   return;
      // } else if (
      //   this.answerType &&
      //   this.answerType != "text" &&
      //   this.options.length <= 0
      // ) {
      //   this.msg = "Please provide options";
      //   this.showsnackbar = true;
      //   return;
      // } else {
      //   this.addQuestion();
      // }
      this.sendApplication();
    },

    sendApplication() {
      if (this.questions) {
        for (let i = 0; i < this.questions.length; i++) {
          if (this.questions[i].answerType == "dropdown") {
            console.log("Drop");
            var ob = {};
            this.questions[i].optionsAnswer = [];
            if (
              this.questions[i].answer.length > 0 &&
              this.questions[i].isMultiSelect
            ) {
              for (let j = 0; j < this.questions[i].answer.length; j++) {
                if (this.questions[i].answer[j]) {
                  ob = {
                    optionvalue: this.questions[i].answer[j],
                  };
                  this.questions[i].optionsAnswer.push(ob);
                }
              }
            } else {
              ob = {
                optionvalue: this.questions[i].answer,
              };
              this.questions[i].optionsAnswer.push(ob);
            }
          }
        }
      }
      var data = {};
      data["questionsAndAnswers"] = this.questions;
      axios({
        url: "/exitInterview/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.question = null;
            this.options = [];
            this.answerType = null;
            this.order = null;
            this.isRequired = false;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    validateQnEdit() {
      if (!this.editingitem.question) {
        this.msg = "Please provide a question";
        this.showsnackbar = true;
        return;
      } else if (!this.editingitem.answerType) {
        this.msg = "Please choose an answer type";
        this.showsnackbar = true;
        return;
      } else if (
        this.editingitem.answerType &&
        this.editingitem.answerType != "text" &&
        this.editingitem.options.length <= 0
      ) {
        this.msg = "Please provide options";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["question"] = this.editingitem.question;
      data["answerType"] = this.editingitem.answerType;
      data["options"] = this.editingitem.options;
      data["order"] = this.editingitem.order;
      data["isRequired"] = this.editingitem.isRequired;
      axios({
        url: "/exitInterviewQuestions/add/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
